module.exports = {

    author: "@nend",
    siteTitle: "Pamo",
    siteShortTitle: "Pamo", // Used as logo text in header, footer, and splash screen
    siteDescription: "Experienced IT Consultants and Branding Specialists",
    siteUrl: "https://pamo.ca/",
    siteLanguage: "en_US",
    siteIcon: "src/content/favicon.png", // Relative to gatsby-config file

    splashScreen: false, // Set this to true if you want to use the splash screen

    // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
    // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
    //mediumRssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantin.muenster",
    mediumRssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fnend.ca/0956e777bf29ca453d1eaab11c1f41/rss",
    shownArticles: 3,
    
    // There are icons available for the following platforms: 
    // Medium, GitHub, LinkedIn, XING, Behance
    socialMedia: [
        /*{
            name: "Linkedin",
            url: "https://www.linkedin.com/in/pamo/"
        },
        {
            name: "Medium",
            url: "https://medium.com/@pamo"
        },
        {
            name: "Blog",
            url: "https://www.pamo.ca"
        },*/
    ],
  
    navLinks: {
        menu: [
            {
                name: "About Us",
                url: "/#about",
            },
            {
                name: "Services",
                url: "/#services",
            },
        ],
        button: {
            name: "Contact",
            url: "/#contact",
        }
    },

    footerLinks: [
        /*{
            name: "Privacy",
            url: "/privacy"
        },
        {
            name: "Imprint",
            url: "/imprint"
        }*/
    ]
}